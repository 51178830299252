import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Image from '../components/Image.js'
import CallToAction from '../components/CallToAction.js'
import TeamList from '../components/TeamList.js'
import Layout from '../components/Layout.js'
import './AboutPage.css'

// Export Template for use in CMS preview
export const AboutPageTemplate = ({
  title,
  sectionHero,
  sectionIntro,
  team = [],
  sectionMedia
}) => (
  <div className="about-page">
    <PageHeader title={title} backgroundImage={sectionHero.featuredImage} />

    {/* content section */}

    <section>
      {!!sectionIntro.columnLeft && (
        <div className="thin half flex">
          <h3>{sectionIntro.title}</h3>
          <div className="item">
            <p>{sectionIntro.columnLeft}</p>
          </div>
          <div className="item">
            <p>{sectionIntro.columnRight}</p>
          </div>
        </div>
      )}

      <div className="separate" />

      {/* quote section */}

      {!!sectionIntro.image && (
        <div className="quote wide half flex">
          <div className="item">
            <Image src={sectionIntro.image} alt={sectionIntro.quote} />
          </div>
          <div className="item">
            <h2 className="alter">"{sectionIntro.quote}"</h2>
          </div>
        </div>
      )}

      <div className="separate" />

      {/* team section */}

      <div className="center">
        <h2>Our Team</h2>
      </div>
      <div className="thin">{!!team && <TeamList list={team} />}</div>
    </section>

    {/* awards section */}

    {!!sectionMedia && (
      <section className="yellow">
        <div className="wide">
          <div className="center">
            <h5>FEATURED</h5>
            <h2>Media & Awards</h2>
          </div>

          <div className="media flex">
            {sectionMedia.map((item, index) => (
              <a
                href={item.article}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Image src={item.image} alt="Media & Awards" />
              </a>
            ))}
          </div>
        </div>
      </section>
    )}

    <CallToAction />
  </div>
)

const AboutPage = ({ data: { page, team } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <AboutPageTemplate
      {...page}
      {...page.frontmatter}
      team={team.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      body={page.html}
    />
  </Layout>
)

export default AboutPage

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        sectionHero {
          featuredImage
        }
        sectionIntro {
          title
          columnLeft
          columnRight
          quote
          image
        }
        sectionMedia {
          article
          image
        }
      }
    }
    team: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "team" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          frontmatter {
            name
            position
            portrait
            social {
              facebook
              twitter
              instagram
              linkedin
            }
            qualifications
            description
          }
        }
      }
    }
  }
`
