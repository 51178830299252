import React from 'react'
import Popup from 'reactjs-popup'

import Image from './Image'
import Content from './Content'

import './TeamList.css'

const TeamList = ({ list, subtitle }) => (
  <div className="team-list third flex">
    {list.map((item, index) => (
      <Popup
        key={index}
        trigger={
          <div className="item" key={index}>
            <div className="image">
              <Image src={item.portrait} alt="" />
            </div>
            <div className="dets">
              <h3>{item.name}</h3>
              <h5>{item.position}</h5>
            </div>
          </div>
        }
        modal
      >
        {close => (
          <div className="popup">
            <div
              className="close"
              onClick={() => {
                close()
              }}
            />
            <div className="wrap">
              <div className="flex">
                <div className="item">
                  <div className="image">
                    <Image src={item.portrait} alt="" />
                  </div>
                </div>
                <div className="item">
                  <h2>{item.name}</h2>
                  <div className="divide" />
                  {!!item.social && (
                    <div className="social">
                      {item.social.twitter && (
                        <a href={item.social.twitter}>
                          <i className="fab fa-twitter" />
                        </a>
                      )}
                      {item.social.instagram && (
                        <a href={item.social.instagram}>
                          <i className="fab fa-instagram" />
                        </a>
                      )}
                      {item.social.linkedin && (
                        <a href={item.social.linkedin}>
                          <i className="fab fa-linkedin" />
                        </a>
                      )}
                      {item.social.facebook && (
                        <a href={item.social.facebook}>
                          <i className="fab fa-facebook" />
                        </a>
                      )}
                    </div>
                  )}

                  <Content className="qual" src={item.qualifications} />
                </div>
              </div>
              <Content src={item.description} />
            </div>
          </div>
        )}
      </Popup>
    ))}
  </div>
)

export default TeamList
